@import '../template/less/app';

.helpers-loop (@i) when (@i > 0) {
  .padding-top-@{i} {
	padding-bottom: ~"@{i}%";
  }
  .padding-bottom-@{i} {
	padding-bottom: ~"@{i}%";
  }
  .helpers-loop(@i - 1);
}

.helpers-loop (25);

.h-50{
  height: 50px;
}

.img-center{
  margin: 0 auto;
}

.cursor-pointer {
  cursor: pointer;
}

.btn i.fa{
  margin-right: 6px;
}

.owl-carousel .item{
  margin: 3px;
}

.owl-carousel .item img{
  display: block;
  width: 100%;
  height: auto;
}

.expand {
  width:99% !important;
  white-space:normal !important;
}
.no-padding-{
  &left{
	padding-left: 0 !important;
  }
  &right{
	padding-right: 0 !important;
  }
  &top{
	padding-top: 0 !important;
  }
  &bottom{
	padding-bottom: 0 !important;
  }
  padding: 0 !important;
}

.no-margin-{
  &left{
	margin-left: 0 !important;
  }
  &right{
	margin-right: 0 !important;
  }
  &top{
	margin-top: 0 !important;
  }
  &bottom{
	margin-bottom: 0 !important;
  }
  margin: 0 !important;
}

.login-content {
  text-align: center;

  &:after {
	content: "";
	vertical-align: middle;
	display: inline-block;
	width: 1px;
	height: 100%;
  }

  &:before {
	height: 50%;
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;
	background: @m-cyan;
	content: "";
	z-index: 0;
  }
}

.note-insert{
  display: none !important;
}

.profile-menu > a .profile-info{
	margin-top: 5px;
}

.cursor-default{
  cursor: default;
}

.lv-item-comment{
  padding-top: 2.5px !important;
  padding-bottom: 2.5px !important;
}

.ms-reply{
	margin-top: 50px !important;
}

.ms-date{
  color: rgba(0, 0, 0, 0.86);
}

.ms-actions {
  display: block;
  color: #B3B3B3;
  margin-top: 7px;

  &  > a > i {
	font-size: 18px;
	vertical-align: bottom;
	line-height: 100%;
  }
}

#top-search-wrap{
  .btn{
	height: 40px;
	padding-top: 7px;
  }
  .col-sm-10{
	padding-right: 0 !important;
  }
  .col-sm-2{
	padding-left: 0 !important;
  }
  #top-search-close{
	z-index: 999;
  }
}

.lv-message {
  .lv-item {
	&:not(.right) {
	  .ms-item {
		background: #eee;
		color: #5E5E5E;
	  }
	}
  }
}

.block-header{
  padding-left: 0 !important;
}
.dropdown-menu.open{
  z-index: 999;
}

.btn-blue{
  color: #0a6ebd;
  :hover{
	color: #085c9e;
  }
}
.zmdi-download{
  font-size: 36px !important;
  color: red !important;
}
.lvh-label{
  color: #434040;
}
.profile-info{
  display: none !important;
}
.profile-menu-custom {
  & > a {
	display: block;
	height: 129px;
	margin-bottom: 5px;
	width: 100%;
	background: url(images/logo.png) no-repeat left top;
	background-size: 100%;

	.profile-pic {
	  padding: 12px;

	  & > img {
		width: 47px;
		height: 47px;
		border-radius: 50%;
		border: 3px solid rgba(0, 0, 0, 0.14);
		box-sizing: content-box;
	  }
	}

	.profile-info {
	  background: rgba(0, 0, 0, 0.37);
	  padding: 7px 14px;
	  color: #fff;
	  margin-top: 20px;
	  position: relative;

	  & > i {
		font-size: 19px;
		line-height: 100%;
		position: absolute;
		right: 15px;
		top: 7px;
		.transition(all);
		.transition-duration(300ms);
	  }
	}
  }
  .main-menu {
	display: none;
	margin: 0 0 0;
	border-bottom: 1px solid #E6E6E6;

  }

  &.toggled {
	.profile-info {
	  & > i {
		.rotate(180deg)
	  }
	}
  }
}
