*, button, input, i, a {
    -webkit-font-smoothing: antialiased;
}

*,
*:active,
*:hover {
    outline: none !important;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0) !important;
}

html {
    overflow-x: ~"hidden\0/";
    -ms-overflow-style: none;
}

html, body {
    min-height: 100vh; 
} 

body {
    font-weight: 400;
    position: relative;
}

audio, video {  
      outline: none;
}

p {
    margin-bottom: 20px;
}

small {
    font-size: 11px;
}

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
    small {
        font-size: 12px;
    }
}

#main {
    position: relative;
    padding-bottom: @footer-height;
    padding-top: @header-height + 30;
}

.container {
    &.c-alt {
        max-width: 1170px;
    }
}

 @media (min-width: @screen-sm-min) and (max-width: @screen-md-max) {
    #content {
        padding-left: 15px;
        padding-right: 15px;
    }
}

body {
    &.sw-toggled {
        #content {
            @media (min-width: @screen-lg-min) {
                padding-left: @sidebar-left-width;
            }

            & > .container {
                @media (min-width: @screen-lg-min) {
                    width: ~"calc(100% - 30px)";
                }
            }
        }
    }
}